import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Theme from "../../styles/theme"
import "../../styles/index.scss"
import { Footer, Header } from ".."
import styled from "styled-components"

const Layout = ({ children, transparentHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Theme>
        <StyledContainer>
          <Header
            siteTitle={data.site.siteMetadata.title}
            transparentHeader={transparentHeader}
          />
          <main>{children}</main>
          <Footer />
        </StyledContainer>
      </Theme>
    </>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
`

Layout.defaultProps = {
  transparentHeader: null,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  transparentHeader: PropTypes.bool,
}

export default Layout
