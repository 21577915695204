import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import dayjs from "dayjs"
import styled from "styled-components"

const BlogList = ({ posts }) => {
  const [currentUrl, setCurrentUrl] = useState("")
  useEffect(() => {
    if (typeof window == "undefined") return
    setCurrentUrl(window.location.pathname)
  }, [])
  return (
    <>
      {posts.map(({ node: post }, i) => {
        return (
          <Blog key={i}>
            <Link to={post.frontmatter.path} state={{ prevUrl: currentUrl }}>
              <div>
                <BlogTitle>{post.frontmatter.title}</BlogTitle>
                <BlogDate>
                  {dayjs(post.frontmatter.date).format("MMM DD, YYYY")}
                  {post.frontmatter.author &&
                    ` | by ${post.frontmatter.author}`}
                  {post.frontmatter.length &&
                    ` | ${post.frontmatter.length} min read`}
                </BlogDate>
                <BlogExcerpt>{post.excerpt}</BlogExcerpt>
              </div>
            </Link>
          </Blog>
        )
      })}
    </>
  )
}

const Blog = styled.div`
  padding: 2rem;
  transition: 0.4s all;
  > a {
    color: var(--gray-600);
    text-decoration: none;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: var(--gray-200);
    transform: scale(1.01);
    border-radius: 0.3rem;

    h4 {
      color: var(--primary-light);
    }
  }
`

const BlogTitle = styled.h4`
  color: var(--primary);
  font-weight: bold;
  letter-spacing: 0.02em;
  margin: 0;
  line-height: 1.4em;
`
const BlogDate = styled.h3`
  font-size: 1.3rem;
  margin: 0rem;
  color: var(--gray-600);
`
const BlogExcerpt = styled.p`
  color: var(--gray-800);
  font-size: 1.5rem;
  margin: 0.8rem 0 0;
`

export default BlogList
