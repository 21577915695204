import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

// Utilities
import kebabCase from "lodash/kebabCase"

const AuthorProfile = ({ className, postAuthor, authorImageFluid }) => {
  return (
    <div
      css={`
        padding: 2rem 0;
      `}
    >
      <Card className={className}>
        <CardImg>
          <Img fluid={authorImageFluid} />
        </CardImg>
        <CardBody>
          <StyledLink to={`/authors/${kebabCase(postAuthor.name)}/`}>
            <CardAuthor>{postAuthor.name}</CardAuthor>
          </StyledLink>
          <CardDescription>{postAuthor.bio}</CardDescription>
        </CardBody>
      </Card>
    </div>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--gray-700);
`

const Card = styled.div`
  background: var(--gray-300);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  text-align: left;
  width: 100%;
  flex-direction: column;
  @media (min-width: ${props => props.theme.responsive.sm}) {
    flex-direction: row;
    width: 100%;
  }
`
const CardImg = styled.div`
  width: 70%;
  flex: 1 1 0;
  padding: 2rem;
  margin: 0 auto;
  > div {
    border-radius: 999999px;
  }
`
const CardBody = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 2 1 0;
  // padding-left: 0.5rem;
`
const CardAuthor = styled.h4`
  font-weight: bold;
  margin: 0;
  letter-spacing: 0;
  text-transform: none;
`

const CardDescription = styled.span`
  font-size: var(--text-base);
  line-height: 1.7em;
`

AuthorProfile.defaultProps = {
  author: "Noah Wong",
  authorDescription: "Just another guy doing his thing.",
  authorImage:
    "https://s.marketwatch.com/public/resources/images/MW-HS874_DogHea_ZH_20191008121436.jpg",
}

AuthorProfile.propTypes = {
  author: PropTypes.string,
  authorDescription: PropTypes.string,
}

export default AuthorProfile
