import React from "react"
import { Link } from "gatsby"
import Routes from "./routes"
import styled from "styled-components"

const StyledNavbar = styled.ul`
  font-size: var(--text-sm);
  color: var(--gray-100);
  display: none;
  list-style: none;
  margin: 0rem;
  text-transform: uppercase;
  li {
    color: inherit;
    display: inline;
    margin-right: 2.4rem;
    margin-left: 0rem;
    &:last-child {
      margin-right: 0;
    }
    > a {
      color: inherit;
      text-decoration: none;
      transition: all 0.3s;
      &:hover {
        color: var(--gray-400);
      }
    }
  }
  @media (min-width: ${props => props.theme.responsive.sm}) {
    display: block;
  }
`

const Navbar = () => {
  return (
    <StyledNavbar>
      {Routes.slice(1).map((link, index) => {
        return (
          <li key={index}>
            <Link to={link.path} activeClassName="active">
              {link.name}
            </Link>
          </li>
        )
      })}
    </StyledNavbar>
  )
}

export default Navbar
