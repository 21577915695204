import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

export type PageDividerProps = {
  fluid: any
  children?: React.ReactNode
  className?: string
}

const PageDivider: React.FunctionComponent<PageDividerProps> = ({
  className,
  children,
  fluid,
}) => {
  return (
    <Wrapper className={className}>
      <Content>{children}</Content>
      <BackgroundImage fluid={fluid} />
    </Wrapper>
  )
}

// styled components

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 65vh;
  min-height: 300px;
  max-height: 600px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
`

const Content = styled.div`
  max-width: 650px;
  padding: 1rem;
  z-index: 10;
  color: white;
  font-size: 3rem;
`

const BackgroundImage = styled(Img)`
  position: absolute !important;
  overflow: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
`

export default PageDivider
