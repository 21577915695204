import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const PaginationLinks = ({ currentPage, numberOfPages }) => {
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === numberOfPages
  const previousPage =
    currentPage - 1 === 1 ? "/blog/" : "/page/" + (currentPage - 1).toString()
  const nextPage = "/page/" + (currentPage + 1).toString()

  // console.log(
  //   "\n",
  //   "Current Page:",
  //   currentPage,
  //   "\n",
  //   "Is Last Page?",
  //   isLastPage,
  //   "\n",
  //   "Is First Page?",
  //   isFirstPage,
  //   "\n",
  //   "Number of Pages:",
  //   numberOfPages
  // )
  const disabledFirst = !isFirstPage ? "" : "disabled-link"
  const disabledlast = !isLastPage ? "" : "disabled-link"

  return (
    <Pagination>
      {isFirstPage ? (
        <Link to="/" className={`${disabledFirst}`}>
          PREV
        </Link>
      ) : (
        <Link to={previousPage}>PREV</Link>
      )}
      {Array.from({ length: numberOfPages }, (_, i) =>
        currentPage === i + 1 ? (
          <Link
            activeClassName="active-pagination"
            to={`/${i === 0 ? "blog/" : "page/" + (i + 1)}`}
            key={i}
          >
            {i + 1}
          </Link>
        ) : (
          <Link to={`/${i === 0 ? "blog/" : "page/" + (i + 1)}`} key={i}>
            {i + 1}
          </Link>
        )
      )}
      {isLastPage ? (
        <Link to={nextPage} className={`${disabledlast}`}>
          NEXT
        </Link>
      ) : (
        <Link to={nextPage}>NEXT</Link>
      )}
    </Pagination>
  )
}

const Pagination = styled.div`
  text-align: center;
  padding: 2rem;
  > a {
    color: var(--primary);
    margin: 0 0.5rem;
    text-decoration: none;
    border-radius: 10%;
    cursor: pointer;
    padding: 0.8rem;
    transition: all 0.3s ease-in;
    &:hover {
      background: var(--primary-light);
      color: var(--white);
    }
  }
`
export default PaginationLinks
