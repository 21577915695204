import React from "react"
import styled from "styled-components"

const StyledBurger = styled.button`
  z-index: 200;
  padding: 1.6rem;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  @media (min-width: ${props => props.theme.responsive.sm}) {
    display: none;
  }

  div.burger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2.4rem;
    height: 2.4rem;
    overflow: hidden;

    span {
      display: block;
      width: 100%;
      height: 1px;
      background: ${props =>
        props.isOpen ? props.theme.colors.primary : props.theme.colors.gray500};
      transition: all 0.3s ease-in;
      position: relative;
      transform-origin: 1px;

      :first-child {
        transform: ${props => (props.isOpen ? "rotate(45deg)" : "")};
      }

      :nth-child(2) {
        opacity: ${props => (props.isOpen ? "0" : "1")};
        transform: ${props =>
          props.isOpen ? "translateX(100%)" : "translateX(0%)"};
      }

      :nth-child(3) {
        transform: ${props => (props.isOpen ? "rotate(-45deg)" : "")};
      }
    }
  }
`

const Burger = props => {
  return (
    <StyledBurger onClick={props.handleButtonClick} isOpen={props.isOpen}>
      <div className="burger">
        <span />
        <span />
        <span />
      </div>
    </StyledBurger>
  )
}

export default Burger
