import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Navbar from "./Navbar"
import Menu from "./Menu"
import Burger from "./Burger"
import Logo from "./Logo"
import "./styles.css"

const Header = ({ transparentHeader }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    let top = document.body.getBoundingClientRect().top
    if (top === 0) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  const handleButtonClick = () => {
    setIsOpen(!isOpen)
    const el = document.body.classList.contains("fixed-body")
    if (el) {
      document.body.classList.remove("fixed-body")
    } else {
      document.body.classList.add("fixed-body")
    }
  }

  const handleLinkClick = () => {
    setIsOpen(false)
    document.body.classList.remove("fixed-body")
  }

  return (
    <HeaderSection
      transparentHeader={transparentHeader}
      isVisible={isVisible}
      id="header"
    >
      <HeaderContainer>
        <Logo
          handleLinkClick={handleLinkClick}
          isOpen={isOpen}
          isVisible={isVisible}
        />
        <Navbar />
        <Burger handleButtonClick={handleButtonClick} isOpen={isOpen} />
        <Menu handleLinkClick={handleLinkClick} isOpen={isOpen} />
      </HeaderContainer>
    </HeaderSection>
  )
}

const HeaderSection = styled.header`
  background: ${props =>
    props.transparentHeader && !props.isVisible
      ? "transparent"
      : "var(--gray-900)"};

  position: fixed;
  padding: 0.8rem 0;
  transition: all 0.2s linear;
  width: 100%;
  z-index: 10;
  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`

const HeaderContainer = styled.div`
  color: var(--gray-500);
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  margin: 0 auto;
  max-width: ${props => props.theme.maxWidth.xl6};
  padding: 0 0 0 1.6rem;
  @media (min-width: ${props => props.theme.responsive.sm}) {
    padding-right: 1rem;
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
  transparentHeader: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  transparentHeader: null,
}

export default Header
