import React from "react"
import { string, number } from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLogo = styled.div`
  z-index: 1000;
  .nStyle {
    fill: ${props => props.nColor};
  }
  .dubStyle {
    fill: ${props => props.dubColor};
  }
`

const Logo = ({ width, nColor, dubColor, handleLinkClick }) => {
  return (
    <StyledLogo nColor={nColor} dubColor={dubColor}>
      <Link to="/" onClick={handleLinkClick}>
        <svg width={width} viewBox="0 0 500 175">
          <path
            className="nStyle"
            d="M469.9 121.4c0 2-.2 4.1-.7 6.3-.5 2.2-1.2 4.4-2.2 6.6-1 2.2-2.2 4.3-3.8 6.3-1.5 2-3.4 3.8-5.6 5.3-2.2 1.5-4.7 2.8-7.6 3.7-2.9.9-6.1 1.4-9.8 1.4H402c-2 0-4.1-.2-6.3-.7s-4.4-1.2-6.6-2.2c-2.2-1-4.3-2.2-6.3-3.8-2-1.5-3.8-3.4-5.3-5.6s-2.8-4.7-3.7-7.6c-.9-2.9-1.4-6.2-1.4-9.8V25.9h21.2v95.5c0 2.6.8 4.7 2.4 6.2 1.6 1.5 3.7 2.3 6.1 2.3h38.2c2.7 0 4.7-.8 6.2-2.4 1.5-1.6 2.2-3.6 2.2-6.2V92.7c0-2.7-.8-4.7-2.4-6.2-1.6-1.5-3.6-2.2-6.1-2.2H402V63.1h38.2c2 0 4.1.2 6.3.7 2.2.5 4.4 1.2 6.6 2.2 2.2 1 4.3 2.2 6.3 3.8s3.8 3.4 5.3 5.6 2.7 4.7 3.7 7.6 1.4 6.1 1.4 9.8v28.6zM354.5 140.4c0 1.5-.3 2.9-.8 4.2-.5 1.3-1.3 2.4-2.2 3.4-.9.9-2.1 1.7-3.3 2.2-1.3.5-2.6.8-4.1.8h-47.8c-2.6 0-5.4-.3-8.3-.9-3-.6-5.9-1.6-8.7-2.9-2.9-1.3-5.7-3-8.3-5s-5-4.5-7-7.4c-2-2.9-3.6-6.3-4.8-10-1.2-3.8-1.8-8.1-1.8-12.9V63.1h21.2v48.8c0 2.8.5 5.2 1.4 7.4.9 2.2 2.2 4.1 3.9 5.7 1.6 1.6 3.5 2.8 5.7 3.6 2.2.8 4.6 1.3 7.1 1.3h37V63.1h21.2v77.3zM239 121.4c0 3.6-.5 6.9-1.4 9.8-.9 2.9-2.1 5.5-3.7 7.6s-3.3 4.1-5.3 5.6-4.1 2.8-6.3 3.8-4.4 1.7-6.7 2.2c-2.3.5-4.4.7-6.4.7H171c-3.6 0-6.9-.5-9.8-1.4-2.9-.9-5.4-2.2-7.6-3.7-2.2-1.5-4.1-3.3-5.6-5.3s-2.8-4.1-3.8-6.3-1.7-4.4-2.2-6.7c-.5-2.3-.7-4.4-.7-6.3V92.7c0-5.4 1-10 3-13.8 2-3.8 4.4-6.8 7.4-9.2s6.2-4.1 9.6-5.1c3.5-1 6.7-1.5 9.6-1.5h38.2v21.2h-38c-2.8 0-5 .7-6.4 2.2s-2.2 3.5-2.2 6.3v28.5c0 2.9.7 5 2.2 6.5 1.4 1.5 3.5 2.2 6.3 2.2h38.2c2.9 0 5-.7 6.4-2.2 1.4-1.5 2.1-3.6 2.1-6.3V25.9H239v95.5z"
          />
          <path
            className="dubStyle"
            d="M123.6 151.1h-21.2v-48.8c0-2.8-.5-5.2-1.4-7.4-.9-2.2-2.2-4.1-3.9-5.7s-3.5-2.8-5.7-3.6c-2.2-.8-4.6-1.3-7.1-1.3h-37v66.8H26.1V73.6c0-1.5.3-2.8.8-4.1.5-1.3 1.3-2.4 2.3-3.3 1-.9 2.1-1.7 3.4-2.2 1.3-.5 2.7-.8 4.2-.8h47.7c2.7 0 5.5.3 8.4.9 3 .6 5.9 1.6 8.8 2.9 2.9 1.3 5.7 3 8.3 5s5 4.5 7 7.4c2 2.9 3.6 6.3 4.8 10 1.2 3.8 1.8 8.1 1.8 12.9v48.8z"
          />
        </svg>
      </Link>
    </StyledLogo>
  )
}

Logo.propTypes = {
  width: number,
  nColor: string,
  dubColor: string,
}

Logo.defaultProps = {
  width: 100,
  nColor: "#6aa4ff",
  dubColor: "#1753b2",
}

export default Logo
