import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledMenuItem = styled.li`
  color: inherit;
  transition: all 0.8s ease-in-out;
  transition-delay: ${props => props.delay + "s"};
  opacity: ${props => (props.isOpen ? "1" : "0")};
  transform: ${props => (props.isOpen ? "translateX(0%)" : "translateX(-25%)")};
  display: block;
  margin: 1.6rem 0;
  padding: 0;
  a {
    font-size: ${props => props.theme.fontSizes.xl3};
    /* color: var(--gray-500); */
    color: inherit;
    text-decoration: none;
    &:hover {
      color: var(--gray-900);
    }
  }
`

const MenuItem = props => {
  return (
    <StyledMenuItem isOpen={props.isOpen} delay={props.delay * 0.1}>
      <Link
        to={props.path}
        onClick={props.handleLinkClick}
        activeClassName="active"
      >
        {props.name}
      </Link>
    </StyledMenuItem>
  )
}

export default MenuItem
