import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

type SectionProps = {
  children?: React.ReactNode
  className?: string
  backgroundColor?: string
  minHeight?: string
  paddingTop?: string
  paddingBottom?: string
}

const SectionComponent: React.FunctionComponent<SectionProps> = ({
  children,
  className,
  backgroundColor,
  minHeight,
  paddingTop,
  paddingBottom,
}) => (
  <Section
    backgroundColor={backgroundColor}
    className={className}
    minHeight={minHeight}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
  >
    {children}
  </Section>
)

const Section = styled.section<SectionProps>`
  padding-top: ${props =>
    props.paddingTop ? props.paddingTop + " !important" : "4rem"};
  padding-bottom: ${props =>
    props.paddingBottom ? props.paddingBottom + " !important" : "4rem"};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor + " !important" : "inherit"};
  min-height: ${props =>
    props.minHeight ? props.minHeight + "px !important" : "auto"};
`

SectionComponent.defaultProps = {}

SectionComponent.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
}

export default SectionComponent
