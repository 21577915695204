import React from "react"
import styled from "styled-components"

type ContainerProps = {
  backgroundColor?: string
  className?: string
  overflow?: string
  position?: string
  maxWidth?: number
  height?: number
}

const ContainerComponent: React.FunctionComponent<ContainerProps> = ({
  children,
  className,
  backgroundColor,
  overflow,
  position,
  maxWidth,
}) => {
  return (
    <Container
      backgroundColor={backgroundColor}
      className={className}
      overflow={overflow}
      position={position}
      maxWidth={maxWidth}
    >
      {children}
    </Container>
  )
}

// styled components
const Container = styled.div<ContainerProps>`
  background: ${props => props.backgroundColor};
  overflow: ${props => props.overflow};
  position: ${props => props.position};
  margin: 0 auto;
  width: 100%;
  max-width: ${props =>
    props.maxWidth ? props.maxWidth + "px" : props.theme.maxWidth.xl6};
  padding: 0 1.6rem;
`

export default ContainerComponent
