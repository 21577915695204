import React from "react"
import styled from "styled-components"
import MenuItem from "./MenuItem"
import Routes from "./routes"

const StyledMenu = styled.nav`
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.5s ease-in-out;
  transform: ${props => (props.isOpen ? "translateX(0)" : "translateX(-100%)")};
  text-align: center;
  width: 100%;
  z-index: 100;
  ul {
    color: inherit;
    margin: 0 auto;
    padding: 0;
  }
  @media (min-width: ${props => props.theme.responsive.sm}) {
    display: none;
  }
`

const Menu = props => {
  return (
    <StyledMenu isOpen={props.isOpen}>
      <ul>
        {Routes.map((item, index) => {
          return (
            <MenuItem
              key={index}
              path={item.path}
              name={item.name}
              handleLinkClick={props.handleLinkClick}
              isOpen={props.isOpen}
              delay={index}
            />
          )
        })}
      </ul>
    </StyledMenu>
  )
}

export default Menu
