import React from "react"
import { CSSProp } from "styled-components"
import { ThemeProvider } from "styled-components"

// fixes styled-components CSS prop error
declare module "react" {
  interface Attributes {
    css?: CSSProp
  }
}

declare module "styled-components" {
  export interface DefaultTheme {
    // Your theme stuff here
    colors: {}
    maxWidth: any
    responsive: any
    fontSizes: any
  }
}

const theme = {
  colors: {
    primary: "#1753B2",
    primaryLight: "#6AA4FF",
    primaryLightest: "#C2D9FF",
    accent: "#B29559",
    accentLight: "#FFECC6",
    gray100: "#f7fafc",
    gray200: "#edf2f7",
    gray300: "#e2e8f0",
    gray400: "#cbd5e0",
    gray500: "#a0aec0",
    gray600: "#718096",
    gray700: "#4a5568",
    gray800: "#2d3748",
    gray900: "#1a202c",
    white: "#fff",
    black: "#000",
  },
  fontSizes: {
    xs: "1.2rem",
    sm: "1.4rem",
    base: "1.6rem",
    lg: "1.8rem",
    xl: "2rem",
    xl2: "1.5rem",
    xl3: "2.4rem",
    xl4: "3.6rem",
    xl5: "4.8rem",
    xl6: "6.4rem",
  },
  font:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
  maxWidth: {
    sm: "38.4rem",
    md: "44.8rem",
    lg: "51.2rem",
    xl: "57.6rem",
    xl2: "67.2rem",
    xl3: "76.8rem",
    xl4: "89.6rem",
    xl5: "102.4rem",
    xl6: "115.2rem",
  },

  responsive: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
