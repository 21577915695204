import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Section } from ".."

const Footer: React.FunctionComponent = () => (
  <Section backgroundColor="var(--gray-900)">
    <FooterOuter>
      <FooterInner>
        <div>
          Copyright &#169; {new Date().getFullYear()} Noah Wong. All rights
          reserved.
        </div>

        <div>
          <ul>
            <li>
              <Link to="/about/">About</Link>
            </li>
            <li>
              <Link to="/portfolio">Portfolio</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </FooterInner>
    </FooterOuter>
  </Section>
)

const FooterOuter = styled.div`
  padding: 3.5rem 1rem;
`

const FooterInner = styled.footer`
  align-items: flex-start;
  border-top: 1px solid rgb(255, 255, 255, 0.1);
  color: dimgrey;
  display: block;
  font-size: ${props => props.theme.fontSizes.sm};
  justify-content: space-between;
  max-width: ${props => props.theme.maxWidth.xl5};
  margin: 0 auto;
  padding-top: 1.6rem;
  text-align: center;
  ul {
    list-style: none;
    margin: 1.6rem;
    padding: 0;
    text-align: center;
    @media (min-width: ${props => props.theme.responsive.sm}) {
      text-align: right;
    }
    li {
      display: inline;
      margin-right: 1.6rem;
      a {
        color: dimgrey;
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
          color: var(--gray-300);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    @media (min-width: ${props => props.theme.responsive.sm}) {
      margin-top: 0;
    }
  }
  @media (min-width: ${props => props.theme.responsive.sm}) {
    display: flex;
  }
`

export default Footer
